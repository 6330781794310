import React from 'react'
import BookButtons from '../../components/BookButtons'
import Layout from '../../components/Layout'

function BookSelection() {
  return (
    <Layout>
      <div
        style={{ minHeight: '85vh' }}
        className="px-4 bg-light-grey sm:px-16"
      >
        <BookButtons />
      </div>
    </Layout>
  )
}

export default BookSelection
